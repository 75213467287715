/**
 * Created by osirvent on 07/09/2016.
 */
angular.module('annexaApp')
    .directive('annexaBoxReceiversThird',['$compile', function ($compile) {
        var getTemplate = function(scope) {
            var template = '';
            template += '<div ng-if="receivers.length==0" class="text-center cursiva-grey" translate="global.thirds.literals.emptySenders">Add</div>';
            template += '<div ng-if="receivers.length>0">';
            template += '   <div class="row p-b-sm b-b ng-class:{\'m-b\' : !$last}" ng-repeat="receiver in receivers" >';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs _600">';
            template += '               <div class="col-md-5">';
            template += '                   <span title="{{receiver.agent.corporateName}}" ng-if="receiver.agent.corporateName">{{receiver.agent.corporateName}}</span>';
            template += '                   <span title="{{receiver.agent.name + \' \' + receiver.agent.surename1}}" ng-if="!receiver.agent.corporateName && !receiver.agent.surename2">{{receiver.agent.name + \' \' + receiver.agent.surename1}}</span>';
            template += '                   <span title="{{receiver.agent.name + \' \' + receiver.agent.surename1 + \' \' + receiver.agent.surename2}}" ng-if="!receiver.agent.corporateName && receiver.agent.surename2">{{receiver.agent.name + \' \' + receiver.agent.surename1+ \' \' + receiver.agent.surename2}}</span>';
            template += '               </div>';
            template += '               <div class="col-md-4 col-xl-3">';
            //template += '                   <span>{{receiver.third.identificationDocument}}</span>';
            //template += '               <div class="col-xs-10 col-sm-5 hellip ">';
            template += '                   <a class="text-primary _600" href="" ui-sref="annexa.thirds.edit({id: receiver.agent.id})" target="_blank"> {{receiver.agent.identificationDocument}}</a>';
            template += '               </div>';
            template += '               <div class="col-md-3 col-xl-4">';
            template += '                   <span class="label show success" ng-if="receiver.revoked" translate="global.literals.revoked">Rebocat</span> ';
            template += '                   <span class="label show danger" ng-if="!receiver.revoked && !isValid(receiver)" translate="global.literals.expired">Caducada</span> ';
            template += '                   <span ng-if="receiver.revoked || !isValid(receiver)" translate="global.literals.the">el</span> ';
            template += '                   <span class="label show success" ng-if="!receiver.revoked && isValid(receiver)" translate="global.literals.current">Vigent</span> ';
            template += '                   <span ng-if="!receiver.revoked && isValid(receiver)" translate="global.literals.dateTo">date to</span> ';
            template += '                   {{receiver.endValidityPeriod | date:\'dd/MM/yyyy\'}}';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-xs-1" permission permission-only="permissions">';
            template += '           <annexa-delete-button delete-function="deleteFunction" delete-object="receiver" delete-object-index="$index" actual="true" class="pull-right"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                receivers: '=',
                deleteFunction: '=',
                permissions: '='
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();

                scope.isValid = function(object) {
                    var valid = true;
                    if(object && object.endValidityPeriod < new Date()){
                        valid = false;
                    }
                    return valid;
                };
                $compile(element.contents())(scope);
            }
        };
    }])
    .directive('annexaBoxDocumentsThird',['$compile', function ($compile) {
        var getTemplate = function(scope) {
            var template = '';
            template += '<div ng-if="documents.length==0" class="text-center cursiva-grey" translate="global.thirds.literals.emptyDocs">Add</div>';
            template += '<div ng-if="documents.length>0">';
            template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last} " ng-repeat="document in documents">';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs p-b-xs">';
            template += '               <div class="col-xs-12 col-sm-6 hellip ">';
            template += '                   <annexa-href-permission-document document="document" name-properties="\'code\'" origin="THIRD"></annexa-href-permission-document>';
            template += '               </div>';
            template += '               <div class="col-xs-10 col-sm-6 _600 hellip">';
            template += '                   <span title="{{document.name}}" >{{document.name}}</span>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-xs-1" permission permission-only="permissions">';
            template += '           <annexa-delete-button delete-function="deleteFunction" delete-object="document" delete-object-index="$index" class="pull-right"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                documents: '=',
                deleteFunction: '=',
                permissions: '='
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        };
    }])
    .directive('annexaBoxConsentsThird', ['$compile', 'Language', function ($compile, Language) {
        var getTemplate = function(scope, languageColumn) {
            var template = '';

            template += '<div ng-if="consents.length==0" class="text-center cursiva-grey" translate="global.thirds.literals.emptyConsents">Add</div>';

            template += '<div ng-if="consents.length>0">';
            template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last}" ng-repeat="consent in consents" >';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs ">';
            template += '               <div class="col-xs-3">';
            template += '                   <span>{{ \'global.literals.\' + consent.consentType | translate }}</span>';
            template += '               </div>';
            template += '               <div class="col-xs-4">';
            template += '                   <span>{{consent.title}}</span>';
            template += '               </div>';
            template += '               <div class="col-xs-5">';
            template += '                   <span>{{consent.thirdAddress.name}}</span>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div class="col-xs-1" permission permission-only="permissions">';
            template += '           <annexa-delete-button delete-function="deleteFunction" delete-object="consent" delete-object-index="$index" actual="true" class="pull-right"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                consents: '=',
                deleteFunction: '=',
                permissions: '='
            },
            link: function (scope, element, attr) {
                scope.languageColumn = Language.getActiveColumn();

                element.html(getTemplate(scope, scope.languageColumn)).show();

                $compile(element.contents())(scope);
            }
        };
    }])
    .directive('annexaBoxContactsThird',['$compile', 'Language', function ($compile, Language) {
        var getTemplate = function(scope, languageColumn) {
            var template = '';
            template += '<div ng-if="contacts.length==0" class="text-center cursiva-grey" translate="global.thirds.literals.emptyContacts">Add</div>';
            template += '<div class="striped p-x" ng-if="contacts.length>0">';
            template += '   <div class="row p-y-sm" ng-repeat="contact in contacts" >';
            template += '       <div class="col-xs-11">';
            template += '           <div class="row m-b-xs ">';
            template += '               <div class="col-xs-1">';
            template += '                   <i ng-if="contact.addressType == \'TELEMATIC_EMAIL\'" title="{{\'global.thirds.addressTypes.TELEMATIC_EMAIL\' | translate}}" class="fa fa-envelope fa-fw"></i>';
            template += '                   <i ng-if="contact.addressType == \'TELEMATIC_MOBILEPHONE\'" title="{{\'global.thirds.addressTypes.TELEMATIC_MOBILEPHONE\' | translate}}" class="fa fa-mobile fa-fw fa-mob-esp"></i>';
            template += '                   <i ng-if="contact.addressType == \'POSTAL\'" title="{{\'global.thirds.addressTypes.POSTAL\' | translate}}" class="fa fa-map-marker fa-fw"></i>';
            template += '               </div>';
            template += '               <div class="col-md-10 _600" ng-if="showContact">';
            template += '                   <div ng-bind-html="getAdressesHtml(contact)"></div>';
            template += '               </div>';
            template += '               <div class="col-md-1">';
            template += '                   <button type="button" title="{{ contact.default ? \'global.literals.default_channel\' : \'global.literals.mark_as_default_channel\' | translate}}" class="md-btn p-a-0 md-flat md-btn__star" ng-disabled="!canEditThird()" ng-click="defaultFunction(contact, $index)"><span class="sr-only">{{ contact.default ? \'global.literals.default_channel\' : \'global.literals.mark_as_default_channel\' | translate}}</span><i class="fa" ng-class="{\'fa-star-o\' : !contact.default,\'fa-star text-warn\' : contact.default}" aria-hidden="true" ></i></button>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '       <div ng-if="editFunction" class="col-xs-1" permission permission-only="permissions">';
            template += '           <annexa-edit-button edit-function="editFunction" edit-object="receiver" edit-object-index="$index" actual="true" class="pull-right"></annexa-edit-button>';
            template += '       </div>';
            template += '       <div class="col-xs-1" permission permission-only="permissions">';
            template += '           <annexa-delete-button delete-function="deleteFunction" delete-object="receiver" delete-object-index="$index" actual="true" class="pull-right"></annexa-delete-button>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                contacts: '=',
                deleteFunction: '=',
                editFunction: '=',
                defaultFunction: '=',
                permissions: '=',
                canEditThird: '='
            },
            link: function (scope, element, attr) {
                scope.languageColumn = Language.getActiveColumn();
                scope.showContact = true;
                scope.getAdressesHtml = function(contact){
                    var thirdAddress = new ThirdAddress(undefined,scope.languageColumn);
                    return thirdAddress.getAddressHtml(contact, false);
                };
                element.html(getTemplate(scope, scope.languageColumn)).show();
                scope.$on('contactChangedEvent', function (event, args) {
                    if(args && args.contact && args.index >= 0){
                        scope.showContact = false;
                        scope.showContact = true;
                    }
                });
                $compile(element.contents())(scope);
            }
        };
    }])
    .directive('annexaBoxRegisterEntriesThird',['$compile', function ($compile) {
        var getTemplate = function(scope) {
            var template = '';
            template += '<div ng-if="registerEntries.length==0" class="text-center cursiva-grey" translate="global.reg.literals.empty_registerEntries_not_add">Add</div>';
            template += '<div class="striped p-x" ng-if="registerEntries.length>0">';
            template += '   <div class="row  " >';
            template += '       <div class="col-xs-12">';
            template += '           <div class="row m-b-xs " ng-repeat="registerEntry in registerEntries">';
            template += '               <div class="col-xs-1">';
            template += '                   <i class="fa fa-sign-in" ng-if="registerEntry.entryType == \'INPUT\'"></i>';
            template += '                   <span class="fa-transX-3px" ng-if="registerEntry.entryType != \'INPUT\'">';
            template += '                       <i class="fa fa-sign-out fa-flip-horizontal" ></i></span>';
            template += '                   </span>';
            template += '               </div>';
            template += '               <div class="col-xs-10 col-sm-5 hellip ">';
            template += '                   <annexa-href-permission-reg-input register-entry="registerEntry"></annexa-href-permission-reg-input>';
            template += '               </div>';
            template += '               <div class="col-xs-push-1 col-sm-push-0 hellip col-sm-6 _600">';
            template += '                   <span>{{registerEntry.extract}}</span>';
            template += '               </div>';
            template += '               <div class="col-sm-2">';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';

            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                registerEntries: '=',
                deleteFunction: '=',
                permissions: '='
            },
            link: function (scope, element, attr) {
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        };
    }])
    .directive('annexaBoxDossiersThird',['$compile', 'Language', function ($compile, Language) {
        var getTemplate = function(scope) {
            var template = '';
            template += '<div ng-if="dossiers.length==0" class="text-center cursiva-grey" translate="global.reg.literals.empty_dossiers_not_add">Empty</div>';
            template += '<div ng-if="dossiers.length>0">';
            template += '   <div class="row ng-class:{\'m-b-p-b-sm-b-b\' : !$last} " ng-repeat="dossier in dossiers">';
            template += '       <div class="col-xs-12">';
            template += '           <div class="row m-b-xs ">';
            template += '               <div class="col-xs-10 col-sm-3 hellip ">';
            template += '                   <annexa-href-permission-dossier dossier="dossier"></annexa-href-permission-dossier>';
            template += '               </div>';
            template += '               <div class="_600 hellip text-left col-sm-3">';
            template += '                   <span title="{{dossier.procedure[languageColumn]}}">{{dossier.procedure[languageColumn]}}</span>';
            template += '               </div>';
            template += '               <div class="_600 hellip text-left" ng-class="dossier.closed ? \' col-sm-3\' : \' col-sm-6\'">';
            template += '                   <span title="{{dossier.subject}}">{{dossier.subject}}</span>';
            template += '               </div>';
            template += '               <div class="col-sm-3 col-0" ng-if="!dossier.closed">';
            template += '                   <span class="label label-xxs" translate="global.literals.inProgress">In proces</span>';
            template += '               </div>';
            template += '               <div class="col-sm-5" ng-if="dossier.closed">';
            template += '                   <span class="label label-xxs success" ng-if="dossier.dossierStatus != \'CANCELLED\'" translate="global.literals.ended">Ended</span>';
            template += '                   <span class="label label-xxs danger" ng-if="dossier.dossierStatus == \'CANCELLED\'" translate="CANCELLED">Cancelled</span>';
            template += '                   <span class="small text-success" >{{dossier.endDate | date: \'dd/MM/yyyy HH:mm:ss\'}}</span>';
            template += '               </div>';
            template += '           </div>';
            template += '       </div>';
            template += '   </div>';
            template += '</div>';
            return template;
        };

        return {
            restrict: 'E',
            replace: true,
            scope: {
                dossiers: '=',
                deleteFunction: '=',
                permissions: '='
            },
            link: function(scope, element, attr) {
                scope.languageColumn = Language.getActiveColumn();
                element.html(getTemplate(scope)).show();
                $compile(element.contents())(scope);
            }
        };
    }]);
