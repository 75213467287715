/**
 * Created by aguerrero on 14/04/2016.
 */
angular
    .module('annexaApp')
    .factory('ThirdFactory',['TableFilter', 'DTColumnBuilder', '$filter', 'Language', '$q', '$http', '$rootScope', 'globalModals', 'AnnexaFormlyFactory', 'RestService', 'CommonService', 'HelperService', 'AnnexaModalFactory', 'GlobalDataFactory', 
   	function(TableFilter, DTColumnBuilder, $filter, Language, $q, $http, $rootScope, globalModals, AnnexaFormlyFactory, RestService, CommonService, HelperService, AnnexaModalFactory, GlobalDataFactory) {
        var factory = {};

        factory.familyProcedures = [];
        factory.familyProceduresTree = [];
        factory.procedures = [];
        factory.organs = [];

        factory.thirdTypes = [
            {"id": "PHISICAL", "name": "global.thirds.literals.phisical"},
            {"id": "LEGAL", "name": "global.thirds.literals.legal"},
            {"id": "PERSONWITHOUTLEGALPERSONALITY", "name": "global.thirds.literals.personwithoutlegalpersonality"}
        ];
        factory.thirdIdentificationDocumentTypes = [
            {"id": "DNI", "name": "DNI/NIF"},
            {"id": "NIE", "name": "NIE/NIF"},
            {"id": "NIF", "name": "NIF"},
            {"id": "OTHER", "name": (($rootScope.esetMode)?"global.thirds.literals.passport":"global.literals.other")}
        ];

        factory.getOrgans = function () {
            return RestService.findAll('Organ', 'third')
                .then(function(data) {
                    factory.organs = JSOG.decode(data.data);
                }).catch(function(error) {
                    factory.organs = [];
                });
        };

        factory.getFilter = function (langCol) {

            var tfilter = [
                { id: 'identificationDocument', type: 'text', order: 0, label: 'global.literals.identifier'},
                { id: 'name', type: 'text', order: 1, label: 'global.literals.name'},
                { id: 'city', type: 'text', order: 2, label: 'global.literals.city', callAux: true},
                { id: 'email', type: 'text', order: 3, label: 'global.profile.email', callAux: true},
                { id: 'phone', type: 'text', order: 4, label: 'global.profile.phone', callAux: true}
            ];
            return tfilter;

        };
        /************************** modificat per APOP 10/01/2018*********************/
        // REGIO DE ADREÇES //
        //nova incorporació de factoria per gestionar adreçes//
        /****************************************************************************/
        /*
        factory.getAdressHtml = function(third, langColumn) {

            var retAdresses = [];
            var third = third;
            var langColumn = langColumn;

            var getDefaultAddressHtml = function() {
                var defaultAddress = getDefaultAddress();

                if(defaultAddress) {
                    switch (defaultAddress.addressType) {
                        case 'POSTAL':
                            return getPostalAddressHtml(defaultAddress);
                        case 'TELEMATIC_EMAIL':
                            return getEmailAddressHtml(defaultAddress);
                        case 'TELEMATIC_MOBILEPHONE':
                            return getMobilePhoneAddressHtml(defaultAddress);
                        default:
                            return '';
                    }
                } else {
                    return '';
                }
            };

            var getOtherDefaultAdressesHtml = function () {
                var otherDefaultAdressesHtml = [];
                var self = this;

                angular.forEach(getOtherDefaultAdresses(), function (value, key) {
                    var address = '';

                    switch(value.addressType) {
                        case 'POSTAL':
                            address = getPostalAddressHtml(value);
                            break;
                        case 'TELEMATIC_EMAIL':
                            address = getEmailAddressHtml(value);
                            break;
                        case 'TELEMATIC_MOBILEPHONE':
                            address = getMobilePhoneAddressHtml(value);
                            break;
                    }

                    if(address != '') {
                        otherDefaultAdressesHtml.push(address);
                    }

                });

                return otherDefaultAdressesHtml;
            };

            retAdresses.push(getDefaultAddressHtml());
            angular.forEach(getOtherDefaultAdressesHtml(), function (value, key) {
                retAdresses.push(value);
            })
            var getDefaultAddress = function() {
                return $linq(third.addresses).singleOrDefault(null, "x => x.default == 1");
            };

            var getOtherDefaultAdresses = function () {
                return $linq(third.addresses).where("x => x.default == 0 && x.defaultByType == 1").toArray();
            };

            var getMobilePhoneAddressHtml = function (address) {
                if(address.addressType != 'TELEMATIC_MOBILEPHONE') {
                    return '';
                }

                var addressHtml = '';

                addressHtml += '<i class="fa fa-mobile text-black-lt fa-fw fa-mob-esp"></i>';
                if(address.phoneInternationalPrefix) {
                    addressHtml += '<a class="text-primary"  href="tel:+' + address.phoneInternationalPrefix + address.telematicValue + '"> +' + address.phoneInternationalPrefix + address.telematicValue + '</a>';
                } else {
                    addressHtml += '<a class="text-primary"  href="tel:+34' + address.telematicValue + '"> ' + address.telematicValue + '</a>';
                }

                return addressHtml;
            };

            var getEmailAddressHtml = function (address) {
                if(address.addressType != 'TELEMATIC_EMAIL') {
                    return '';
                }

                var addressHtml = '';

                addressHtml += '<i class="fa fa-envelope fa-fw text-black-lt" aria-hidden="true"></i>';
                addressHtml += '<span>';
                addressHtml += '<a class="text-primary" href="mailto:' + address.telematicValue + '"> ' + address.telematicValue + '</a>';
                addressHtml += '</span>';

                return addressHtml;
            };

            var getPostalAddressHtml = function(address) {
                if(address.addressType != 'POSTAL') {
                    return '';
                }

                var addressHtml = '';

                addressHtml += '<i class="fa fa-map-marker fa-fw text-black-lt"></i>';
                addressHtml += '<span>';

                if(address.validStreet) {
                    if(addres.validStreet.streetType){
                        addressHtml += validStreet.streetType[langColumn]+ ' ';
                    }
                    addressHtml += validStreet.name+ ' ';
                } else {
                    if(address.streetType) {
                        addressHtml += ' ' + address.streetType[langColumn];
                    }
                    addressHtml += ' ' + address.street + ', ';
                    if(address.kilometer) {
                        addressHtml += 'Km. ' + address.kilometer;
                    }
                    if(address.numberAddres) {
                        addressHtml += 'Num. ' + address.numberAddres;
                    }
                    addressHtml += ' - ';
                    if(address.postalCode) {
                        addressHtml += 'C.P. ' + address.postalCode + " ";
                    }
                }
                if(address.validCity) {
                    addressHtml += address.validCity.name;
                } else {
                    if(address.city) {
                        addressHtml += address.city;
                    }
                }
                if(address.country) {
                    addressHtml += ' ('+ address.country[langColumn] + ')';
                }

                addressHtml += '</span>';

                return addressHtml;
            };

            return retAdresses;
         }

        */

        // END REGIO DE ADREÇES //
        /****************************************************************************/

        factory.getListColumns = function($scope) {
            var thirdNameColumn = function(data, type, full, meta){
                var content = "";
                if(full.thirdType == "PHISICAL"){
                    content = '<strong>'+full.name +'&nbsp;'+full.surename1+ ((full.surename2)?'&nbsp;'+full.surename2:'')+'</strong>';
                }else if(full.corporateName){
                    content = '<strong>'+full.corporateName+'</strong>';
                }
                return content;
            };

            var getHtmlContentRepresentations = function(data, type, full, meta){
                var content = "";
                if(full.representations && full.representations.length > 0){
                    if(full.representations.length == 1) {
                        if (full.representations[0].agent) {
                            if (full.representations[0].agent.name) {
                                content = '<span>' + full.representations[0].agent.name + '&nbsp;' + full.representations[0].agent.surename1 + ((full.representations[0].agent.surename2)?'&nbsp;'+full.representations[0].agent.surename2:'') + '</span>';
                            } else if (full.corporateName) {
                                content = '<span>' + full.representations[0].agent.corporateName + '</span>';
                            }
                        }else{
                            content = '<span translate="global.thirds.literals.datatable.col3"></span>';
                        }

                    }else{
                        content = '<span class="pull-left m-r-xs">' + full.representations.length + '</span> <span class="pull-left-fa" translate="global.thirds.literals.datatable.col3"></span>';
                    }

                }
                return content;
            };

            var getHtmlContentAdresses = function(data, type, full, meta){
                var content = "";
                if(full){
                    var thirdAddress = new ThirdAddress(full, Language.getActiveColumn());
                    content = thirdAddress.getDefaultAddressHtml();
                }
                return content;
            };

            var thirdTypeColumn = new IconFilterColumn($scope, 'filterData', 'thirdType',
                [
                    new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                    new IconSearchOption('PHISICAL','PHISICAL', 'fa-user fa-lg ico-third', 'fa-user fa-fw', 'global.thirds.literals.phisical'),
                    new IconSearchOption('LEGAL','LEGAL', 'fa-bank fa-lg ico-third', 'fa-bank fa-fw', 'global.thirds.literals.legal'),
                    new IconSearchOption('PERSONWITHOUTLEGALPERSONALITY','PERSONWITHOUTLEGALPERSONALITY', 'fa-puzzle-piece fa-lg ico-third', 'fa-puzzle-piece fa-fw', 'global.thirds.literals.personwithoutlegalpersonality')
                ], 'tableDefinition');
            var identificationDocumentColumn = new LinkColumn($filter,'global.thirds.literals.datatable.col1','viewThird','id');

            var thirdActionsColumn = new ActionsColumn(
                $filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see','annexa.thirds.edit({ id: \'[data]\'})','fa-eye', true),
                [new ActionButton('global.literals.remove','removeThird([data])').setPermissions('delete_third')]);

            var columns = [
                { id: 'thirdType', column: thirdTypeColumn, sortable: false },
                { id: 'identificationDocument', column: identificationDocumentColumn},
                { id: 'completeName', width: '25%', title:$filter('translate')('global.thirds.literals.datatable.col2'), render: thirdNameColumn},
                { id: 'representations', title:  $filter('translate')('global.thirds.literals.datatable.col3'), render: getHtmlContentRepresentations, sortable: false},
                { id: 'addresses', title: $filter('translate')('global.thirds.literals.datatable.col4'), render: getHtmlContentAdresses, sortable: false },
                { id: 'actions', columnName: 'id', width: '170px', className: 'text-center', title: thirdActionsColumn.getTitle(), render: thirdActionsColumn.getRender, sortable: false}
            ];

            return columns;

        };

        factory.removeThird = function (id) {
            var deferrend = $q.defer();

            $http({
                url: './api/thirds',
                method: 'DELETE',
                params: { id: id }
            }).success(function(data, status) {
                if(status == 500 || status == 406 || status == 403 || status == 404 || status == 409){
                    deferrend.reject(data);
                }else{
                    deferrend.resolve(data);
                }
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });

            return deferrend.promise;
        };

        factory.ConsentBox = {
            initialize: function(title_box, placeholder_search, advanced_search_title, newButton) {
                this.boxDefinition = new BoxDefinition(title_box, $rootScope.LoggedUser.id);
                if(newButton) {
                    this.boxDefinition.addNew(this.newConsent, this.completeAddConsent);
                }
                return this;
            },
            boxDefinition: null,
            newConsent: function() {
                var proccesResult = function (object) {
                    return object.dossierNumber + " (" + object.subject + ")";
                };

                var modal = angular.copy(globalModals.consentsAdd);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = { familyProcedure: {} };
                modal.annexaFormly.options = { watchAllExpressions: true };
                modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.search = function(val) {
                    var filter = {
                        subject: val.val,
                        dossierNumber: val.val
                    };

                    var additional_filter = { };
                    return RestService.filterData('tram', 'Dossier', filter, additional_filter, 0, 10, '', 3)
                        .then(function(data) {
                            var response = [];

                            _.forEach(data.data.content, function (value) {
                                response.push({ id: value.id, object: JSOG.encode(value), value: proccesResult(value) });
                            });

                            response = new SortedArray(response, 'value').sort();

                            return response;
                        });
                };
                
                modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.advancedSearch = function() {
                    var expiredStates = [
                        { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
                        { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
                    ];

                    var tfilterDossier  = new TableFilter();
                    tfilterDossier.addElement('dossierNumber', 'text', 0, 'global.literals.code');
                    tfilterDossier.addElement('family', 'select', 1, 'global.literals.family', 2, 'FamilyProcedure', true, Language.getActiveColumn(), false, undefined, Language.getActiveColumn()).setFilterCallAux(true);
                    tfilterDossier.addElement('procedure', 'text', 2, 'global.literals.procedure').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('subject', 'text', 3, 'global.literals.subject').setUserChosenFilter(true);
                    tfilterDossier.addElement('thirds', 'text', 4, 'global.literals.thirdsInt').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('thirds_identification', 'text', 5, 'global.literals.thirds_identification').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('dates', 'dateRange', 8, 'global.delegate.startDate').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('creator', 'text', 9, 'global.literals.creator').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('register_entry', 'text', 10, 'global.literals.register_entry_INI').setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.addElement('expired', 'select', 11, 'global.literals.expiredSate', 3, HelperService.addAllSelect(expiredStates, 'name', Language.getActiveColumn()), true, 'name', true, undefined, Language.getActiveColumn()).setFilterCallAux(true).setUserChosenFilter(true);
                    tfilterDossier.loadLocalData([],undefined, undefined, Language.getActiveColumn());

                    //var tdDefDossier    = new TableData('tram', 'DossierTransactionView', './api/tram/byfilter').setSortName('maxDays').setSort([[1, 'asc']]);
                    var tdDefDossier    = new TableData('tram', 'Dossier', './api/tram/byfilter').setSortName('remainingDays').setSort([[1, 'asc']]);

                    var DaysColumn          = new RemaingDaysColumn($filter, 'global.literals.days', HelperService);
                    var ProcedureColumn     = new DatabaseTranslatedColumn($filter, 'global.literals.procedure', Language.getActiveColumn());
                    var StDateColumn        = new StartDateColumn($filter, 'global.delegate.startDate');
                    var CreatorUserColumn   = new UserColumn($filter, 'global.literals.creator');
                    var RelatedColumn       = new RelatedDossiersColumn();

                    var tabledataColumnsDossier = [
                        DTColumnBuilder.newColumn(null).renderWith(function(data, type, full, meta){
                        	
                        	// MRS-I expFilterAdv
                            return '<input type="radio" id="'+full.id+'" name ="selected" data-ng-model="radioSelected" data-ng-value="'+ full.id +'" aria-label="{{ \'global.literals.select\' | translate }}">';
                            // MRS-F expFilterAdv
                        
                        }).notSortable(),
                        DTColumnBuilder.newColumn('remainingDays').withTitle(DaysColumn.getTitle()).renderWith(DaysColumn.getRender),
                        DTColumnBuilder.newColumn('dossierNumber').withTitle($filter('translate')('global.literals.code')),
                        DTColumnBuilder.newColumn('procedure.' + ProcedureColumn.getColumn()).withTitle(ProcedureColumn.getTitle()),
                        DTColumnBuilder.newColumn('subject').withTitle($filter('translate')('global.literals.dossier'))
                    ];

                    var advancedModalDefinition = new BoxAdvancedSearchModalDefinition('dossierbox', 'global.literals.advancedSearchExpe', tfilterDossier, tdDefDossier, tabledataColumnsDossier, undefined, undefined, modal.annexaFormly.fields[0].fieldGroup[4].templateOptions.advancedSearchAdd, modal).changeSize('modal-lg');
                    advancedModalDefinition.added = [];

                    AnnexaModalFactory.showBoxAdvancedFilter(advancedModalDefinition);
                };
                modal.extra = this;
                AnnexaFormlyFactory.showModal("modalNewConsent", modal, this.completedNew, false);
            },
            completeAddConsent: function () {
                var proccesResult = function (object) {
                    return object.dossierNumber + " (" + object.subject + ")";
                };

                var model = this.annexaFormly.model.modal_body;

                var consent = { consentType: model.consentType.id, title: '', thirdAddress: model.thirdAddress };

                switch(model.consentType.id) {
                    case 'FAMILY_PROCEDURE':
                        consent.familyProcedure = { id: model.familyProcedure.$selected.id };
                        consent.title = model.familyProcedure.$selected.title;
                        break;
                    case 'PROCEDURE':
                        consent.procedure = model.procedure;
                        consent.title = model.procedure[Language.getActiveColumn()];
                        break;
                    case 'DOSSIER':
                        consent.dossier = model.dossier.object;
                        consent.title = proccesResult(consent.dossier);
                        break;
                }

                this.extra.content.push(consent);

                this.close();

                if(this.extra.saveSearch) {
                    this.extra.saveSearch(consent);
                }
            }
        };

        factory.ContactBox = {
            initialize: function(title_box, placeholder_search, advanced_search_title, newButton) {
                this.boxDefinition = new BoxDefinition(title_box, $rootScope.LoggedUser.id);
                if(newButton) {
                    this.boxDefinition.addNew(this.newContact, this.completeAddContact);
                }
                return this;
            },
            boxDefinition: null,
                newContact: function () {
                var defaultCountry = $rootScope.app.configuration.default_country;

                if(defaultCountry && defaultCountry.value){
                    defaultCountry = defaultCountry.value;
                }else{
                    defaultCountry = '';
                }
                var modal = angular.copy(globalModals.contactsAdd);
                modal.annexaFormly.model = {};
                var showDefaultByTypeMobile = false;
                var defaults;
                defaults = $linq(this.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_MOBILEPHONE\'').toArray();
                if(defaults && defaults.length > 0){
                    showDefaultByTypeMobile = true;
                }
                var showDefaultByTypeEmail = false;
                defaults = $linq(this.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_EMAIL\'').toArray();
                if(defaults && defaults.length > 0){
                    showDefaultByTypeEmail = true;
                }
                var showDefaultByTypePostal = false;
                defaults = $linq(this.content).where('x => x.defaultByType == true && x.addressType == \'POSTAL\'').toArray();
                if(defaults && defaults.length > 0){
                    showDefaultByTypePostal = true;
                }
                var showDefault = true;
                defaults = $linq(this.content).where('x => x.default == true').toArray();
                if(defaults && defaults.length > 0){
                    showDefault = false;
                }

                modal.annexaFormly.model.modal_body = {addressType:'POSTAL', hasStates:true, hasCities: true, hasStreetTypes: true, showDefaultByTypeMobile:showDefaultByTypeMobile, showDefaultByTypeEmail:showDefaultByTypeEmail, showDefaultByTypePostal:showDefaultByTypePostal, showDefault:showDefault};
                if(defaultCountry){
                    modal.annexaFormly.model.modal_body.country = {id:defaultCountry};
                }else{
                    modal.annexaFormly.model.modal_body.country = {};
                }
                modal.extra = this;
                AnnexaFormlyFactory.showModal("modalNewContact", modal, this.completedNew, false);
            },
            completeAddContact: function() {
                var contact = {};

                var contactAux = this.annexaFormly.model.modal_body;
                if(contactAux.country && ((contactAux.validState && contactAux.validState.id) || contactAux.stateAddress) && ((contactAux.validCity && contactAux.validCity.id) || contactAux.city) && contactAux.postalCode && ((contactAux.validStreet && contactAux.validStreet.id) || contactAux.street) && (contactAux.streetType && contactAux.streetType.id) && contactAux.numberAddres){
                    var defaults = $linq(this.extra.content).where('x => x.defaultByType == true && x.addressType == \'POSTAL\'').toArray();
                    contact = { addressType:'POSTAL', key: CommonService.guid()};
                    if(contact.addressType == contactAux.addressType){
                        contact.default = contactAux.showDefault;
                    }
                    if(contactAux.showDefaultByTypePostal) {
                        contact.defaultByType = !contactAux.defaultPostal_defaultByType ? false : contactAux.defaultPostal_defaultByType;
                    }else{
                        contact.defaultByType = true;
                    }
                    if(contact.defaultByType && defaults && defaults.length > 0){
                        angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                    }
                    contact.country = contactAux.country;
                    if(contactAux.hasStates) {
                        if (contactAux.validState && contactAux.validState.id) {
                            contact.validState = contactAux.validState;
                        }
                    }else{
                        contact.stateAddress = contactAux.stateAddress;
                    }
                    if(contactAux.hasCities){
                        if(contactAux.validCity && contactAux.validCity.id) {
                            contact.validCity = contactAux.validCity;
                        }
                    }else{
                        contact.city = contactAux.city;
                    }
                    if(contactAux.hasStreetTypes && contactAux.streetType) {
                        contact.streetType = contactAux.streetType;
                    }
                    if(contactAux.hasStreets){
                        if(contactAux.validStreet && contactAux.validStreet.id) {
                            contact.validStreet = contactAux.validStreet;
                        }
                    }else {
                        contact.street = contactAux.street;
                    }
                    contact.isValidNumber = false;
                    contact.numberAddres = contactAux.numberAddres;
                    contact.floor = contactAux.floor;
                    contact.block = contactAux.block;
                    contact.door = contactAux.door;
                    contact.stair = contactAux.stair;
                    contact.postalCode = contactAux.postalCode;
                    this.extra.content.push(contact);
                }
                if(contactAux.contactPhone){
                    var defaults = $linq(this.extra.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_MOBILEPHONE\'').toArray();
                    contact = { addressType:'TELEMATIC_MOBILEPHONE', key: CommonService.guid() };
                    if(contact.addressType == contactAux.addressType){
                        contact.default = contactAux.showDefault;
                    }
                    if(contactAux.showDefaultByTypeMobile) {
                        contact.defaultByType = !contactAux.defaultNumber_defaultByType ? false : contactAux.defaultNumber_defaultByType;
                    }else{
                        contact.defaultByType = true;
                    }
                    if(contact.defaultByType && defaults && defaults.length > 0){
                        angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                    }
                    contact.phoneInternationalPrefix = contactAux.contactPrefix;
                    contact.telematicValue = contactAux.contactPhone;
                    this.extra.content.push(contact);
                }
                if(contactAux.contactEmail){
                    var defaults = $linq(this.extra.content).where('x => x.defaultByType == true && x.addressType == \'TELEMATIC_EMAIL\'').toArray();
                    contact = { addressType:'TELEMATIC_EMAIL', key: CommonService.guid() };
                    if(contact.addressType == contactAux.addressType){
                        contact.default = contactAux.showDefault;
                    }
                    if(contactAux.showDefaultByTypeEmail) {
                        contact.defaultByType = !contactAux.defaultEmail_defaultByType ? false : contactAux.defaultEmail_defaultByType;
                    }else{
                        contact.defaultByType = true;
                    }
                    if(contact.defaultByType && defaults && defaults.length > 0){
                        angular.forEach(defaults, function(value, key){value.defaultByType = false;});
                    }
                    contact.telematicValue = contactAux.contactEmail;
                    this.extra.content.push(contact);
                }
                if(this.extra.saveSearch) {
                    this.extra.saveSearch();
                }
                this.close();
            }
        };

        factory.getCitiesByState = function(state){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/thirds/getCitiesByState',
                params: {
                    state: state
                }
            }).success(function(data, status) {
                deferrend.resolve(data);
            }).error(function(msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.getStreetsByCity = function(city){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/thirds/getStreetsByCity',
                params: {
                    city: city
                }
            }).success(function(data, status) {
                deferrend.resolve(data);
            }).error(function(msg, code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.existThirdByIdentificationDocument = function (identificationDocument) {
            var deferrend = $q.defer();

            $http({
                url: './api/thirds/byidentification',
                method: 'GET',
                params: { identificationDocument: identificationDocument}
            }).then(function (data) {
                deferrend.resolve(data);
            }).catch(function (error) {
                deferrend.reject(error);
            });

            return deferrend.promise;
        };

        factory.newThird = function(third, user, addresses, documents, representations, consents){
            var decoded = {};
            decoded.third      = third;
            decoded.createUser = user;
            decoded.addresses  = addresses;
            decoded.documents  = documents;
            decoded.thirdRepresentations = representations;
            if(consents) {
                decoded.consents = consents;
            }
            var send = JSOG.encode(decoded);

            var deferrend = $q.defer();
            $http({
                url: './api/thirds',
                method: 'POST',
                data: send
            }).then(function(data){
                deferrend.resolve(JSOG.decode(data.data));
            }).catch(function(error){
                deferrend.reject(error.data);
            });

            return deferrend.promise;
        };

        factory.updateThird = function(third) {
            var deferrend = $q.defer();
            $http({
                url: './api/thirds',
                method: 'PUT',
                data: JSOG.encode(third)
            }).then(function (data) {
                deferrend.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferrend.reject(error);
            });
            return deferrend.promise;
        };

        factory.getThird = function(id) {
            return $http({
                url: './api/thirds',
                method: 'GET',
                params: {id:id}
            }).then(function (data) {
                factory.Third = JSOG.decode(data.data);
            }).catch(function (error) {
            });
        };

        factory.getRelatedTabs = function(id) {
            return $http({
                url: './api/thirds/getRelatedTabs',
                method: 'GET',
                params: {id:id}
            }).then(function (data) {
                var related = JSOG.decode(data.data);
                if(related.dossiers) {
                    factory.relatedDossiers = $linq(related.dossiers).distinctBy("x => x.id").toArray();
                }else{
                    factory.relatedDossiers = [];
                }
                if(related.registerEntries) {
                    factory.relatedRegisterEntries = $linq(related.registerEntries).distinctBy("x => x.id").toArray();
                }else{
                    factory.relatedRegisterEntries = [];
                }
            }).catch(function (error) {
                factory.relatedDossiers = [];
                factory.relatedRegisterEntries = [];
            });
        };

        factory.getFamilyProcedures = function() {
        	if(GlobalDataFactory.familyProcedures && GlobalDataFactory.familyProcedures.length > 0){
        		var deferred = $q.defer();
        		factory.familyProcedures = angular.copy(GlobalDataFactory.familyProcedures);
        		factory.familyProceduresTree = CommonService.getTreeData(factory.familyProcedures, Language.getActiveColumn());
                deferred.resolve(true);
            	return deferred.promise;
        	}else{
        		return RestService.findAll('FamilyProcedure')
	                .then(function (data) {
	                    var decodedData = JSOG.decode(data.data);
	                    factory.familyProcedures = decodedData;
	                    factory.familyProceduresTree = CommonService.getTreeData(factory.familyProcedures, Language.getActiveColumn());
	                }).catch(function(error) {
	                });
        	}
        };

        factory.getProcedures = function () {
            return RestService.findAll('Procedure', 'third')
                .then(function(data) {
                    factory.procedures = $linq(JSOG.decode(data.data)).orderBy("x => x." + Language.getActiveColumn(), linq.caseInsensitiveComparer).toArray();
                }).catch(function (error) {
                });
        };

        factory.getThirdAddresByThird = function(third, dossier){
            var thirdAddress;
            if(third && third.consents && third.consents.length > 0 && dossier && dossier.id ){
                thirdAddress = $linq(third.consents).where("x => x.consentType == 'DOSSIER' && ((x.dossier && x.dossier.id == "+dossier.id+") || (x.dossierId == "+dossier.id+"))").select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                if(!thirdAddress || thirdAddress.length == 0){
                    var idProcedure = (dossier.procedure && dossier.procedure.id)?dossier.procedure.id:-1;
                    thirdAddress = $linq(third.consents).where("x => x.consentType == 'PROCEDURE' && x.procedure.id == "+idProcedure).select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                    if(!thirdAddress || thirdAddress.length == 0){
                        var idFamilyProcedure = (dossier.procedure && dossier.procedure.family && dossier.procedure.family.id)?dossier.procedure.family.id:-1;
                        thirdAddress = $linq(third.consents).where("x => x.consentType == 'FAMILY_PROCEDURE' && x.familyProcedure.id == "+idFamilyProcedure).select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                        if(!thirdAddress || thirdAddress.length == 0){
                            thirdAddress = $linq(third.consents).where("x => x.consentType == 'ALL'").select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                            if(!thirdAddress || thirdAddress.length == 0){
                                thirdAddress = $linq(third.addresses).firstOrDefault(undefined, "x => x.default == true");
                            }else{
                                thirdAddress = thirdAddress[0];
                            }
                        }else{
                            thirdAddress = thirdAddress[0];
                        }
                    }else{
                        thirdAddress = thirdAddress[0];
                    }
                }else{
                    thirdAddress = thirdAddress[0];
                }
            }else if(third && third.addresses){
                thirdAddress = $linq(third.addresses).firstOrDefault(undefined, "x => x.default == true");
            }
            return thirdAddress;
        };

        factory.getThirdAddresWithConsent = function(third, dossier){
            var thirdAddressWithConsent = false;
            var thirdAddress ;
            if(third && third.consents && third.consents.length > 0 && dossier && dossier.id ){
                thirdAddress = $linq(third.consents).where("x => x.consentType == 'DOSSIER' && ((x.dossier && x.dossier.id == "+dossier.id+") || (x.dossierId == "+dossier.id+"))").select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                if(!thirdAddress || thirdAddress.length == 0){
                    var idProcedure = (dossier.procedure && dossier.procedure.id)?dossier.procedure.id:-1;
                    thirdAddress = $linq(third.consents).where("x => x.consentType == 'PROCEDURE' && x.procedure.id == "+idProcedure).select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                    if(!thirdAddress || thirdAddress.length == 0){
                        var idFamilyProcedure = (dossier.procedure && dossier.procedure.family && dossier.procedure.family.id)?dossier.procedure.family.id:-1;
                        thirdAddress = $linq(third.consents).where("x => x.consentType == 'FAMILY_PROCEDURE' && x.familyProcedure.id == "+idFamilyProcedure).select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                        if(!thirdAddress || thirdAddress.length == 0){
                            thirdAddress = $linq(third.consents).where("x => x.consentType == 'ALL'").select("x => x.thirdAddress").distinct("(x, y) => x.id == y.id").toArray();
                            if(!thirdAddress || thirdAddress.length == 0){
                                thirdAddressWithConsent = false;
                            }else{
                                thirdAddressWithConsent = true;
                            }
                        }else{
                            thirdAddressWithConsent = true;
                        }
                    }else{
                        thirdAddressWithConsent = true;
                    }
                }else{
                    thirdAddressWithConsent = true;
                }
            }else if(third && third.addresses){
                thirdAddressWithConsent = false;
            }
            return thirdAddressWithConsent;
        };

        factory.getThirdCompleteName = function (third) {
            return !third.name ?
                third.corporateName :
                third.name + ' ' + third.surename1 + (third.surename2 ? ' ' + third.surename2 : '');
        };

        factory.getRelatedThirdType = function (related) {
            if(related.interested) {
                return $filter('translate')('global.literals.interested');
            } else {
                var representated = '';

                if(related.representated && related.representated.identificationDocument) {
                    representated = factory.getThirdCompleteName(related.representated) + ' (' + related.representated.identificationDocument + ')';
                }

                return $filter('translate')('global.literals.representative', { representated: representated });
            }



        };
        return factory;
    }]);