/**
 * Created by dpardell on 16/09/2016.
 */

        //region Adreces

var ThirdAddress = function(third, langColumn, dossier) {
    this.third = third;
    this.langColumn = langColumn;
    this.dossier = dossier;

    this.getDefaultAddress = function() {
        return $linq(this.third.addresses).firstOrDefault(null, "x => x.default == 1");
    };

    this.getOtherDefaultAdresses = function () {
        return $linq(this.third.addresses).where("x => x.default == 0 && x.defaultByType == 1").toArray();
    };
    //***********************************************************//
    // Esto es una incorporacion nueva sacada del tram prototipe //
    //***********************************************************//
        this.getAdressesHtml = function() {
        	var self = this;
            var retAdresses = [];

            var consentAddress = [];

            if(this.dossier && 
            		this.third.consents && 
            		this.third.consents.length > 0) {
            	//Busca la direccion del consentimiento por prioridad (de mas prioritaria 1 a menos prioritaria 4):
            	
            	//1. Expediente
                consentAddress = $linq(this.third.consents).where("x => x.consentType == 'DOSSIER' && x.dossierId && x.dossierId == " + this.dossier.id).toArray();
                
                //2. Procedimiento
                if (consentAddress.length == 0 && 
                		this.dossier.procedure) {
                	consentAddress = $linq(this.third.consents).where("x => x.consentType == 'PROCEDURE' && x.procedure && x.procedure.id == " + this.dossier.procedure.id).toArray();
                }
                
                //3. Familia de procedimiento
                if (consentAddress.length == 0 &&
                		this.dossier.procedure && 
                		this.dossier.procedure.family) {
                	consentAddress = $linq(this.third.consents).where("x => x.consentType == 'FAMILY_PROCEDURE' && x.familyProcedure && x.familyProcedure.id == " + this.dossier.procedure.family.id).toArray();
                }
                
                //4. Global
                if (consentAddress.length == 0) {
                	consentAddress = $linq(this.third.consents).where("x => x.consentType == 'ALL'").toArray();
                }
                
            }

            if(consentAddress.length > 0) {
            	//Hay direccion de consentimiento
            	angular.forEach(consentAddress, function (value) {
            		retAdresses.push(self.getAddressHtml(value.thirdAddress, true));
            	});
            } else {            	
                retAdresses.push(this.getDefaultAddressHtml());

                if(!this.dossier) {
	                angular.forEach(this.getOtherDefaultAdressesHtml(), function (value, key) {
	                    retAdresses.push(value);
	                });
                }            	
            }

            return retAdresses;
        };

    this.getDefaultAddressHtml = function() {
        var defaultAddress = this.getDefaultAddress();

        if(defaultAddress) {
            switch (defaultAddress.addressType) {
                case 'POSTAL':
                    return this.getPostalAddressHtml(defaultAddress, true);
                case 'TELEMATIC_EMAIL':
                    return this.getEmailAddressHtml(defaultAddress, true);
                case 'TELEMATIC_MOBILEPHONE':
                    return this.getMobilePhoneAddressHtml(defaultAddress, true);
                default:
                    return '';
            }
        } else {
            return '';
        }
    };

    this.getOtherDefaultAdressesHtml = function () {
        var otherDefaultAdressesHtml = [];
        var self = this;

        angular.forEach(this.getOtherDefaultAdresses(), function (value, key) {
            var address = '';

            switch(value.addressType) {
                case 'POSTAL':
                    address = self.getPostalAddressHtml(value, true);
                    break;
                case 'TELEMATIC_EMAIL':
                    address = self.getEmailAddressHtml(value, true);
                    break;
                case 'TELEMATIC_MOBILEPHONE':
                    address = self.getMobilePhoneAddressHtml(value, true);
                    break;
            }

            if(address != '') {
                otherDefaultAdressesHtml.push(address);
            }

        });

        return otherDefaultAdressesHtml;
    };

    this.getAddressHtml = function(address, icon, withoutHtml) {
        if(address) {
            switch (address.addressType) {
                case 'POSTAL':
                    return this.getPostalAddressHtml(address, icon, withoutHtml);
                case 'TELEMATIC_EMAIL':
                    return this.getEmailAddressHtml(address, icon, withoutHtml);
                case 'TELEMATIC_MOBILEPHONE':
                    return this.getMobilePhoneAddressHtml(address, icon, withoutHtml);
                default:
                    return '';
            }
        } else {
            return '';
        }
    };

    this.getMobilePhoneAddressHtml = function (address, icon, withoutHtml) {
        if(address.addressType != 'TELEMATIC_MOBILEPHONE') {
            return '';
        }

        var addressHtml = '';
        if(!withoutHtml) {
            if (icon) {
                addressHtml += '<i class="fa fa-lg fa-mobile text-black-lt fa-fw"></i>';
            }
            addressHtml += '<span>';
            if (address.phoneInternationalPrefix) {
                addressHtml += '<a class="text-primary" href="tel:+' + address.phoneInternationalPrefix + address.telematicValue + '"> +' + address.phoneInternationalPrefix + address.telematicValue + '</a>';
            } else {
                addressHtml += '<a class="text-primary" href="tel:' + address.telematicValue + '"> ' + address.telematicValue + '</a>';
            }
            addressHtml += '</span>';
        } else {
            if (address.phoneInternationalPrefix) {
                addressHtml += '+' + address.phoneInternationalPrefix + address.telematicValue;
            } else {
                addressHtml += address.telematicValue;
            }
        }

        return addressHtml;
    };

    this.getEmailAddressHtml = function (address, icon, withoutHtml) {
        if(address.addressType != 'TELEMATIC_EMAIL') {
            return '';
        }

        var addressHtml = '';

        if(!withoutHtml) {
            if (icon) {
                addressHtml += '<i class="fa fa-envelope fa-fw pull-left text-black-lt"></i>';
            }
            addressHtml += '<span class="text-primary hellip pull-left-fa">';
            addressHtml += '<a class="text-primary" href="mailto:' + address.telematicValue + '" title="' + address.telematicValue + '"> ' + address.telematicValue + '</a>';
            addressHtml += '</span>';
        } else {
            addressHtml += address.telematicValue;
        }

        return addressHtml;
    };
        /************************** modificat per APOP 8/01/2018*********************/
        //introduccio de coletillas en la adressa //
        /****************************************************************************/
    this.getPostalAddressHtml = function(address, icon, withoutHtml) {
        if(address.addressType != 'POSTAL') {
            return '';
        }

        var addressHtml = '';
        if(!withoutHtml) {
            if (icon) {
                addressHtml += '<i class="fa fa-map-marker fa-fw text-black-lt pull-left"></i>';
            }
            addressHtml += '<span class="pull-left-fa">';
        }
        if(address.validStreet) {
            if(address.validStreet.streetType){
                addressHtml += address.validStreet.streetType[this.langColumn]+ ' ';
            }
            addressHtml += address.validStreet.name+ ' ';
        } else {
            if(address.streetType) {
                addressHtml += ' ' + address.streetType[this.langColumn];
            }
            addressHtml += ' ' + address.street + ', ';
        }
        if(address.kilometer) {
            addressHtml += 'Km. ' + address.kilometer + " ";
        }
        if(address.numberAddres) {
            addressHtml += 'Num. ' + address.numberAddres + "  ";
        }
        if(address.block) {
            addressHtml += 'Bl. ' + address.block + "  ";
        }
        if(address.stair) {
            addressHtml += 'Esc. ' + address.stair + "  ";
        }
        if(address.floor) {
            addressHtml += 'Pla. ' + address.floor + "  ";
        }
        if(address.door) {
            addressHtml += 'Pta. ' + address.door + " ";
        }
        addressHtml += ' - ';
        if(address.postalCode) {
            addressHtml += 'C.P. ' + address.postalCode + " ";
        }        if(address.validCity) {
            addressHtml += address.validCity.name + " ";
        } else {
            if(address.city) {
                addressHtml += address.city + " ";
            }
        }
        if(address.validState) {
            addressHtml += address.validState[this.langColumn] + " " ;
        } else {
            if(address.stateAddress) {
                addressHtml += address.stateAddress + " ";
            }
        }
        if(address.country) {
            addressHtml += ' ('+ address.country[this.langColumn] + ')';
        }

        if(!withoutHtml) {
            addressHtml += '</span>';
        }

        return addressHtml;
    }
}

        //endregion

